// 特定商取引法に基づく表記

<template lang="pug">
.public-law

  header.bg-teal-300
    h1.text-teal-800.text-center.font-bold.p-2 おもいでBook作成サイト

  .container.mx-auto.p-3.pb-20.mt-4

    h2.text-3xl.font-bold.text-teal-700.lg__text-center 特定商取引法に基づく表記

    pre.whitespace-pre-wrap.mt-6.text-gray-800
      | 特定商取引法に基づき、以下の情報を表記します。
      |
      | [販売業者]
      | 写真の伊里
      |
      | [運営責任者名]
      | 作道 有哉
      |
      | [住所]
      | 郵便番号: 488-0002
      | 愛知県尾張旭市根の鼻町1-2-12
      |
      | [電話番号]
      | 0561-55-4051 （FAX：0561-55-4052）
      |
      | [メールアドレス]
      | book@11s.jp
      |
      | [商品代金以外の料金の説明]
      | ご購入の際の送料全国一律260円(税別)
      |
      | [お支払い方法]
      | ・クレジットカード決済
      | ・コンビニ決済
      |
      | [お支払い時期]
      | ・クレジットカード: 購入時
      | ・コンビニ決済: 注文より10日以内
      |
      | [商品引渡し時期]
      | 注文確定から約4週間後に発送いたします。
      |
      | [返品・キャンセル・交換について]
      | 商品は受注生産のため、お客様都合による返品・キャンセル・交換はお受けできません。
      |
      | [サイズ違い・品番違い・不良品]
      | 商品の品質には万全を期しておりますが、品質不良等があった場合は、商品到着日より14日以内に当社に到着したもののみ返品及び交換を受け付けるものとします。
      | 当社までお電話をいただいた後、商品の入った封筒と納品書を同封の上、当社まで料金着払いでご返送ください。
      | 当社負担で良品を発送致します。
      | なお、期間経過後は、商品に品質不良があっても、お客様がご了承の上、受領されたものとします。
      |
      | [個人情報の取り扱い]
      | 個人情報保護方針 をご参照ください。

    footer.mt-10.text-center
      button.btn(
        @click='$router.go(-1)'
      ) 戻る

</template>

<script>
export default {
  name: 'PublicLaw'
}
</script>

<style lang="sass" scoped>

</style>
